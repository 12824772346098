<template>
    <div>
        <el-table ref="listTable" v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" prop="id" label="序号" sortable width="80"></el-table-column>
            <el-table-column align="center" prop="name" label="税源名称"></el-table-column>
            <el-table-column align="center" prop="full_name" label="联系人"></el-table-column>
            <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
            <el-table-column align="center" width="100"  label="logo">
                <template slot-scope="scope" >
                    <el-image
                            style="width: 100px; height: 100px"
                            :src="url+scope.row.logo" ></el-image>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="rate" label="申请费率"></el-table-column>
            <el-table-column align="center" prop="apiRate" label="总后台请费率"></el-table-column>
            <el-table-column align="center" width="100"  label="状态">
                <template slot-scope="scope" >
                    <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100"  label="审核状态">
                <template slot-scope="scope" >
                    <span v-if="scope.row.type==0">未审核</span>
                    <span v-if="scope.row.type==1">已审核</span>
                    <span v-if="scope.row.type==2" style="color:#F56C6C ">审核失败</span>
                    <br>
                    <TextOverflow v-if="scope.row.type==2">{{scope.row.reason}}</TextOverflow>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
            <el-table-column align="center" label="操作" >
                <template slot-scope="scope">
                        <!-- <el-popconfirm title="确定要删除这条数据吗" @confirm="deletes(scope.$index, data)">
                            <el-button class="text-danger"  slot="reference"  type="text" size="small"><i class="el-icon-delete"></i>删除</el-button>
                        </el-popconfirm> -->
                        &nbsp;
                        <br>
                        <div v-if="scope.row.type==0">
                            <el-button  @click="examine(scope.row.id,1)" type="text" size="small" >通过</el-button>
                            <el-button  @click="rejects(scope.row.id)" type="text" size="small" >驳回</el-button>
                        </div>
                        <div v-if="scope.row.type==1 && scope.row.apiRate<=0">
                            <el-button  @click="rate(scope.row.id,scope.row.apiRate,scope.row.rate)" type="text" size="small" >费率</el-button>
                        </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="驳回原因" :modal="false" :visible.sync="dialogFormVisible">
            <el-form  label-width="80px" >
                <el-form-item >
                    <el-input type="textarea"  v-model="reason"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="rejectss">驳回</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="费率配置" :modal="false" :visible.sync="apiRate">
            <el-form  label-width="80px" >
                <el-form-item >
                     <el-input-number v-model="rates" :precision="2" :step="1" :min="0" :max="100-maxRate"></el-input-number>
                    <div class="rate">最大费率为{{100-maxRate}}</div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="apiRates">保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    import TextOverflow from "components/common/TextOverflow.vue"
    import {request} from "@/library/request.js";
    export default {
        components:{
			TextOverflow
		},
        data(){
            return{
                data:[],
                url:"",
                loading:false,
                dialogFormVisible: false,
                reason:"",
                rejectId:"",
                apiRate:false,
                rates:"",
                apiRateId:"",
                maxRate:0
            }
        },
        methods:{
            changeSwitch(data){
				let param = new URLSearchParams()
				param.append('status', data.status)
				return request({
					url:'system/service/status/'+data.id,
					method:"post",
					data:param
				}).then(response  => {
					if(response.code!=200){
						this.$Message.error(response.msg)
					}
				}).then(err => {
					if(err){
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
			},
            examine(id,type){
                return request({
					url:'system/service/type/'+id,
					method:"post",
					data:{type:type}
				}).then(response  => {
					if(response.code==200){
						this.getlist()
					}else{
                        this.$Message.error(response.msg)
                    }
				}).then(err => {
					if(err){
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
            },
            rejects(id){
                this.rejectId = id
                this.dialogFormVisible=true
            },
            rejectss(){
                return request({
					url:'system/service/type/'+this.rejectId,
					method:"post",
					data:{type:2,reason:this.reason}
				}).then(response  => {
					if(response.code==200){
						this.getlist()
                        this.dialogFormVisible=false
					}else{
                        this.$Message.error(response.msg)
                    }
				}).then(err => {
					if(err){
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
            },
            getlist(){
                this.loading = true
                return request({
                    url:'system/service/detail/'+this.id,
                    method:"get",
                }).then(response  => {
                    this.loading = false
                    if(response.code!=200){
                        this.$Message.error(response.msg)
                    }
                    this.url = response.data.url
                    this.data = response.data.data
                    console.log(response)
                }).then(err => {
                    if(err){
                        this.loading = false
                        this.$Loading.error();
                        this.$Message.error("系统异常，请稍后重试")
                    }
                })	
            },
            rate(id,apiRate,rate){
                this.apiRateId = id
                this.apiRate = true
                this.rates = apiRate
                 this.maxRate = rate
            },
            apiRates(){
                return request({
					url:'system/service/rate/'+this.apiRateId,
					method:"post",
					data:{rate:this.rates}
				}).then(response  => {
					if(response.code==200){
						this.getlist()
                        this.apiRate=false
					}else{
                        this.$Message.error(response.msg)
                    }
				}).then(err => {
					if(err){
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
                })
            }
        },
        mounted(){
            this.id = this.$route.params.id
            this.getlist()
        }
    }
</script>
<style scoped>
    .rate{
		color:#f56c6c
	}
</style>